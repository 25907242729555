import { activeArray } from '@/utils/localStorage.js'
// 基本字段
let tableData = [
  {
    label: '序号',
    type: 'index',
    align: 'center',
    show: true,
    width: 80
  },
  {
    label: '分片名',
    prop: 'shardName',
    align: 'center',
    show: true
  },
  {
    label: '采集间隔/(毫秒)',
    prop: 'interval',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '是否采集',
    prop: 'cancel',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '创建时间',
    prop: 'createTime',
    show: true,
    enableSlot: true,
    width: 200
  },
  {
    label: '操作',
    prop: 'operators',
    width: 180,
    align: 'right',
    fixed: 'right',
    children: {
      edit: {
        label: '编辑',
        icon: 'ym-icon-bianji',
        // 控制按钮显示隐藏
        showFilter: (item, row, thisVue) => {
          return activeArray('/sync-setting/update-sync-setting')
        },
        clickEvent: (thisVue, row) => {
          thisVue.handleEditor(row)
        }
      },
      delete: {
        label: '删除',
        icon: 'ym-icon-shanchu',
        color: '#FF8851',
        // 控制按钮显示隐藏
        showFilter: (item, row, thisVue) => {
          // return activeArray('/sync-setting/delete-sync-setting')
          // 暂不显示该按钮
          return false
        },
        clickEvent: (thisVue, row) => {
          thisVue.deleteRow(row)
        }
      }
    }
  }
]

// 新增表单配置
let addForm = [
  {
    showbutton: true,
    items: {
      shardName: {
        label: '分片名',
        type: 'text',
        clearable: true,
        placeholder: '分片名',
        disabled: false,
        rules: [
          { required: true, message: '请输入分片名', trigger: 'change' }
        ]
        // children: []
      },
      interval: {
        label: '采集间隔/(毫秒)',
        type: 'number',
        clearable: true,
        placeholder: '采集间隔',
        min: 500,
        rules: [
          { required: true, message: '请输入采集间隔', trigger: 'change' },
          {
            validator: function (rule, value, callback) {
              var re = /^\+?[1-9][0-9]*$/
              // console.log('数据:', value)
              if (value && value >= 500 && re.test(value)) {
                return callback()
              } else if (value < 500) {
                return callback(new Error('最小500毫秒'))
              } else {
                return callback(new Error('只能输入整数'))
              }
            }
          }
        ]
      },
      cancel: {
        label: '是否采集',
        type: 'select',
        children: [
          {
            label: '是',
            value: false
          },
          {
            label: '否',
            value: true
          }
        ],
        rules: [
          { required: true, message: '请选择是否采集数据', trigger: 'change' }
        ]
      }
    }
  }
]

// 表头图标配置
let headData = {
  title: '分片设置列表',
  items: [
    /* 新增按钮 */
    {
      style: 'button',
      label: '新增',
      icon: 'el-icon-plus',
      size: 'medium',
      type: 'primary',
      // 控制按钮显示隐藏
      showFilter: (item, thisVue) => {
        return activeArray('/sync-setting/save-sync-setting')
      },
      clickEvent: (thisVue) => {
        thisVue.dialogTitle = '新增分片设置'
        // 初始化表单
        thisVue.dialogValue = {
          shardName: '',
          interval: '',
          cancel: ''
        }
        thisVue.addForm[0].items.shardName.disabled = false
        thisVue.$set(thisVue, 'dialogShow', true)
        thisVue.closeDialog('addForm')
        // console.log('点击：', thisVue.dialogValue)
      }
    }
  ]
}

let tableObj = {
  'tableData': tableData,
  'headData': headData,
  'addForm': addForm
}

export default tableObj
