<template>
  <div class="search-form">
    <el-form ref="form"
             :model="form"
             label-position="right"
             label-width="100px">
      <el-row style="width:100%">
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="分片名">
            <el-input class="inputWid"
                      @keyup.enter.native="confirm"
                      v-model="form.shardName"
                      clearable
                      placeholder="分片名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="采集间隔">
            <el-input class="inputWid"
                      @keyup.enter.native="confirm"
                      v-model="form.interval"
                      type="number"
                      clearable
                      placeholder="采集间隔/(毫秒)"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="是否采集">
            <el-select class="inputWid"
                       @keyup.enter.native="confirm"
                       v-model="form.cancel">
              <el-option v-for="item in cancelList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="width:100%">
        <el-col class="search-btn"
                :xs="24"
                :sm="24"
                :md="24"
                :lg="24"
                :xl="24">
          <el-form-item>
            <el-button type="primary"
                       @click="confirm">查询</el-button>
            <el-button class="form-item-Left"
                       @click="rest">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      options: [],
      cancelList: [
        {
          label: '全部',
          value: null
        },
        {
          label: '是',
          value: false
        },
        {
          label: '否',
          value: true
        }
      ]
    }
  },
  mounted() {
    // this.getAliases()
  },
  methods: {
    // 分片名切换刷新
    // handleSelect() {
    //   this.$emit('select', this.form.shard)
    // },
    // 分片名数组
    // async getAliases() {
    //   let { data } = await this.$api.home.shard()
    //   if (data) {
    //     let array = [
    //       {
    //         value: null,
    //         label: '全部'
    //       }
    //     ]
    //     for (let item of data) {
    //       let obj = {}
    //       obj.value = item
    //       obj.label = item
    //       array.push(obj)
    //     }
    //     this.options = array
    //   }
    // },

    rest() {
      this.form.shardName = null
      this.form.interval = null
      this.form.cancel = null
      this.confirm()
    },
    confirm() {
      let _form = {
        interval: this.form.interval ? this.testNumber(this.form.interval) : null,
        shardName: this.form.shardName ? this.trim(this.form.shardName) : null,
        cancel: this.form.cancel
      }
      this.$emit('search', _form)
    },
    // 校验是否是整数
    testNumber(value) {
      var re = /^\+?[1-9][0-9]*$/
      if (value && value > 0 && re.test(value)) {
        return value
      } else {
        this.$message.error('只能输入整数')
      }
    },
    trim(s) {
      // 去除目标字符串左右两边的空白字符
      return s.replace(/(^\s*)|(\s*$)/g, '')
    }
  }
}
</script>
