<template>
  <div>
    <table-list :headData="headData"
                :columns="columns"
                :data="this.list"
                :tableOption="{size: 'mini'}"
                :loading="loading"
                fit
                stripe
                :pageData="pageData"
                @handlePageChange="handlePageChange"
                @handleSizeChange="handleSizeChange">

      <!-- 采集间隔 -->
      <template #interval="{row}">
        <span>{{toThousands(row.interval) || '无'}}</span>
      </template>
      <!-- 是否采集 -->
      <template #cancel="{row}">
        <span>{{row.cancel?'否':'是'}}</span>
      </template>
      <!-- 创建时间 -->
      <template #createTime="{ row }">
        {{tf(row.createTime, 'YYYY-MM-DD HH:mm:ss')}}
      </template>

      <!-- 查看按钮 -->
      <!-- <template #row="{row}">
        <el-button class="table-el-btn" type="text"
                   icon="ym-icon-bianji"
                   @click="handleEditor(row.shardName)"> 编辑</el-button>
      </template> -->

    </table-list>

    <!-- 新增表单 -->
    <el-dialog class="tableList-addForm" :close-on-click-modal="false"
               width="700px"
               :title="dialogTitle"
               :visible.sync="dialogShow" @close="closeDialog('addForm')">

      <ymForm label-position="right"
              label-width="120px"
              ref="addForm"
              refCode="addForm"
              :formValue="dialogValue"
              :formData="addForm"
              @onSubmit="onSubmit">
      </ymForm>

      <div class="tableList-form-buttonBox">
        <el-button size="medium"
                   @click="submitForm('addForm')"
                   type="primary"
                   v-loading="isSubmitting">提交 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TableList from '@/components/TableList'
import tableObj from '../component/tableData.js'
import ymForm from '@/components/YmForm'
import timeFormat from '@/utils/timeFormat.js'
import publicFunction from '@/utils/publicFunction.js'
export default {
  props: {
    datas: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    datas: {
      handler() {
        this.pageData.currentPage = 1
        this.getData(1)
      },
      deep: false,
      immediate: true
    }
  },
  components: {
    TableList,
    ymForm
  },
  data() {
    return {
      loading: false,
      isSubmitting: false,
      dialogShow: false,
      columns: tableObj.tableData,
      headData: tableObj.headData,
      addForm: tableObj.addForm, // 表单项
      dialogValue: {
        shardName: '',
        interval: '',
        cancel: ''
      }, // 表单数据
      dialogTitle: '', // 弹窗标题
      list: [], // 列表
      pageData: {
        size: 10, // 每页的数据量
        pageSize: 10, // 每页的条数跳转页面
        pageSizes: [10, 20, 50, 100], // 每页的条数跳转页面
        total: 0, // 总条数
        layout: 'prev, pager, next, total, sizes, jumper, slot',
        currentPage: 1 // 当前页
      } // 分页配置
    }
  },
  created() {},
  mounted() {
    // this.getAliases()
  },
  methods: {
    // 数字加逗号转化为金额类型
    toThousands(num) {
      return publicFunction.toThousands(num)
    },
    // 时间转换
    tf(time, format) {
      return timeFormat(time, format)
    },
    // 清空校验
    closeDialog(formData) {
      if (this.$refs[formData]) {
        this.$refs[formData].resetFields()
      }

      // console.log(this.$refs[formData])
    },
    // 分片名数组
    // async getAliases() {
    //   let { data } = await this.$api.home.shard()
    //   if (data) {
    //     let array = []
    //     for (let item of data) {
    //       let obj = {}
    //       obj.value = item
    //       obj.label = item
    //       array.push(obj)
    //     }
    //     this.addForm[0].items.shardName.children = array
    //   }
    // },
    // 为触发条件赋值
    handleTarget() {
      if (this.form.state && this.form.shard) {
        this.form.target = this.form.state + ':' + this.form.shard
        // console.log('触发条件:', this.form.target)
      }
    },
    // 编辑
    async handleEditor(row) {
      this.dialogTitle = '修改分片设置'
      this.dialogShow = true
      // let body = {
      //   shardName: shardName
      // }
      // const { data } = await this.$api.collection.getShardName(body)
      // 回显表单
      // if (data) {
      this.addForm[0].items.shardName.disabled = true
      this.dialogValue.shardName = row.shardName
      this.dialogValue.interval = row.interval
      this.dialogValue.cancel = row.cancel
      // }
    },
    // 删除
    deleteRow(row) {
      this.$confirm('此操作将永久删除该条记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let body = {
          shardName: row.shardName,
          interval: row.interval,
          cancel: row.cancel,
          deleteFlag: true
        }
        let res = await this.$api.collection.updateShard(body)
        if (res.code === 200) {
          setTimeout(res => {
            this.getData(1)
            this.$message.success('删除成功')
          }, 2000)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 点击提交按钮,触发提交事件（不需要判断是否是编辑）
    submitForm(id) {
      this.$refs[id].onSubmit()
    },
    onSubmit(form, ref) {
      // console.log(form, ref)
      if (this.isSubmitting) {
        return
      }
      let body = {
        shardName: form.shardName,
        interval: form.interval,
        cancel: form.cancel
      }
      this.isSubmitting = true
      this.$api.collection.addShard(body).then((res) => {
        this.dialogShow = false
        if (res.code === 200) {
          this.$message.success('提交成功')
          // 这里由于ES数据更新需要延时
          setTimeout(() => {
            this.getData(1)
          }, 1000)
        } else {
          this.$message.error(res.message)
        }
        setTimeout(() => {
          this.isSubmitting = false
        }, 1000)
      })
    },

    async getData(currentPage) {
      let option = {
        page: currentPage - 1,
        size: this.pageData.size
      }
      let body = {
        shardName: this.datas.shardName,
        interval: this.datas.interval,
        cancel: this.datas.cancel
      }
      let { data, code } = await this.$api.collection.getShardList(option, body)
      if (code === 200) {
        this.pageData.total = data.total
        this.list = data.content
        // console.log('数据1:', this.list)
      }
    },
    handlePageChange(goPage) {
      this.pageData.currentPage = goPage
      this.getData(goPage)
    },
    // 配置每页条数
    handleSizeChange(number) {
      this.pageData.currentPage = 1
      this.pageData.size = number
      this.pageData.pageSize = number
      this.getData(1)
    }
  }
}
</script>

<style lang="scss" scoped>
.table-row:hover {
  text-decoration: underline;
  color: $--color-primary;
}
// 不换行省略
.shenglue {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
// js样式
/deep/ pre {
  background: rgb(230, 230, 230);
  padding: 10px;
  margin: 10px;
  font-size: 16px;
  overflow-x: scroll;
  .string {
    color: green;
  }
  .number {
    color: darkorange;
  }
  .boolean {
    color: blue;
  }
  .null {
    color: magenta;
  }
  .key {
    color: red;
  }
}

.tableList-addForm {
  /deep/.el-dialog {
    max-width: 700px;
    .el-dialog__body {
      padding: 30px 80px 50px;
      .tableList-form-buttonBox {
        text-align: center;
      }
    }
    .el-select {
      width: 100% !important;
    }
    .el-input {
      width: 86% !important;
    }
  }
}
/deep/.tableList-addForm .el-dialog .el-dialog__body .tableList-form-buttonBox {
  text-align: right;
  margin-top: 16px;
}
</style>
