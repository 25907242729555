<template>
  <div class="ym-page">
    <!-- 顶部组件 -->
    <search-form :form="form" @search="search"></search-form>
    <!-- 任务列表 -->
    <table-list :datas="searchVal"></table-list>
  </div>
</template>

<script>
import SearchForm from './component/SearchForm.vue'
import TableList from './component/TableList.vue'
export default {
  components: {
    SearchForm,
    TableList
  },
  data() {
    return {
      // 表单
      form: {
        shardName: null, // 分片名（默认全部）
        interval: null,
        cancel: null
      },
      // 搜索
      searchVal: {
        shardName: null, // 分片名（默认全部）
        interval: null,
        cancel: null
      }
    }
  },
  mounted() {
    // 新手指引
    this.$nextTick(() => {
      this.$guide.myIntroJs(this.$route.name)
    })
  },
  methods: {
    search(form) {
      // 重置表单
      this.form = form
      // 搜索列表
      this.searchVal = form
    }
  }
}
</script>
